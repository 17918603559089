<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title> {{ data.name }} ({{ data.id }}) [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-status v-if="getAccess('saleStatus')" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit :title="'Редактировать'" v-if="!true && editObject" @click="showEditDialog = true" />

      <a-btn-edit :disabled="true" :title="'Просмотр'" v-if="getAccess('showSales', id)" @click="showEditDialog = true" />
    </template>
    <portal to="v-main">
      <StateSaleDialog v-model="showStateSaleDialog" :id="id" :object="JSON.parse(JSON.stringify(data))" />
    </portal>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row v-if="loaded">
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <v-tabs v-model="tab_photo" background-color="transparent" slider-color="white">
              <v-tab class="mr-3">
                {{ "Фото объекта" }}
              </v-tab>
              <v-tab class="mr-3">
                {{ "Дизайн-проект" }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab_photo" class="transparent" style="min-height: 200px" :style="{ height: blockHeight + 'px' }">
            <v-tab-item>
              <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
            </v-tab-item>
            <v-tab-item>
              <a-view-images :value="data.photos_design" :style="{ height: blockHeight + 'px' }" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">основная информация</div>
          </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" class="block_master" />
        </material-card>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <material-card color="third">
          <template v-slot:heading>
            <v-tabs dense v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active>
              <v-tab v-for="(tab, i) in tabs" :key="i" :href="`#tab-${tab.name}`" class="mr-3">
                {{ tab.title }}
                <v-icon v-if="tab.icon">{{ tab.icon }}</v-icon>
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-if="!loading" v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(tab, i) in tabs" :key="i" :id="`tab-${tab.name}`" :data-name="`${tab.name} section`">
              <TabDocs v-if="'doc,doc_adv,doc_law,doc_furniture'.split(',').includes(tab.name)" :data="data" :category="showDocs(tab.name)" props="readonly" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, autoHeightBlock, statusChange, fillForm, genModel, modelOptions } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, statusChange, genModel, modelOptions, fillForm],
  props: {
    idShow: Number,
    type: String,
  },
  components: {
    editSiteDialog: () => import("./../dialogs/objectSiteDialog"),
    editDialog: () => import("./../dialogs/salesDialog"),
    StateSaleDialog: () => import("./../dialogs/stateSaleDialog"),
    TabDocs: () => import("./tabs/tabDocs"),
  },
  data() {
    return {
      id: 0,
      loading: false,
      loaded: false,
      idEditWork: 0,
      idEditGoods: 0,
      showStateSaleDialog: false,
      showWorkDialog: false,
      showGoodsDialog: false,
      showEditDialog: false,
      confirmDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.sales,
      types: {
        objects: { url: "/mechti/objects/broker" },
        lampObjects: { url: "/mechti/lamp_objects" },
        agentObjects: { url: "/mechti/agent_objects" },
        partnerObjects: { url: "/mechti/partner_objects" },
      },
      tab: 0,
      tab_photo: 0,
    };
  },
  computed: {
    api() {
      return this.types[this.objectType].url;
    },
    apiStatus() {
      return this.types[this.objectType].url;
    },
    objectType() {
      return this.type || this.$route.params.type;
    },
    modelRight() {
      console.log("modelRight");
      let model = [];
      if (this.loaded) model = this.calcModel("viewForm1");
      return model;
    },
    tabs() {
      let tabs = [{ name: "doc_adv", title: "Реклама" }];
      return tabs;
    },
    model() {
      return this.getModelList(this.m, "form", true);
    },
    editObject() {
      //console.log(this.$root.profile);
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    statuses() {
      let res = [];
      let model;
      if (
        (model = this.model.find(m => {
          return m.name == "status";
        }))
      ) {
        res = this.getOptions(model);
      }
      return res;
    },
    statusItems() {
      let items = [];
      let res = [];
      let status = {};
      let type;
      type = "status_fin";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter(s => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }
      type = "status_site";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter(s => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }

      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex(el => {
        return el.value == this.data.status_fin && el.field == "status_fin";
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
  },
  created() {
    this.$root.title = "Продажи";
    this.id = this.idShow || Number(this.$route.params.id);
    //this.id = Number(this.$route.params.id);
    //this.updateData(this.id);
    this.fitchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        //this.updateData(this.id);
        this.fitchData();
      }
    },
  },
  methods: {
    showDocs(field) {
      return this.model.find(el => el.name == field)?.category || -1;
    },
    getStatus(type) {
      let idx = this.statuses.findIndex(el => {
        if (el.formula) {
          let func = eval(el.formula);
          return func(this.data[type]);
        }
        return el.value == this.data[type] && el.field == type;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    async fitchData() {
      let data = {};
      let objectType = this.objectType;
      if (!objectType) return;
      let api = this.api;
      const d = await this.$axios.get(api + "/" + this.id);
      if (d.data && d.data.data) {
        data = d.data.data;
      }
      for (const name in data) {
        if (name == "data" && this.data?.data && data[name]) {
          for (const nameData in data.data) {
            this.$set(this.data.data, nameData, data.data[nameData]);
          }
        } else {
          this.$set(this.data, name, data[name]);
        }
      }
      this.afterFetchData();
    },
    afterFetchData() {
      this.loading = false;
      this.loaded = !false;
    },
    updateData_() {
      console.log("updateData");
      this.fitchData();
    },
  },
};
</script>
